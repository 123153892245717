import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage as Image, getImage } from 'gatsby-plugin-image';
import * as React from "react";
import LogoSrc from '../../images/nop/logo.svg';
import PropTypes from '../../utils/PropTypes';

import Button from '../Button';
import Link from '../Link';


const NOPSidebarBanner = ({ medium }) => {
  const data = useStaticQuery(graphql`
    query {
      cachepotImage: file(relativePath: {eq: "nop/cachepot.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: NONE
            width: 270
          )
        }
      }
      candlestickImage: file(relativePath: {eq: "nop/candlestick.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: NONE
            width: 270
          )
        }
      }
    }
  `);

  const imageUrlParams = `?utm_source=folklore&utm_medium=${medium}&utm_campaign=general&utm_content=image`;
  const titleUrlParams = `?utm_source=folklore&utm_medium=${medium}&utm_campaign=general&utm_content=title`;
  const buttonUrlParams = `?utm_source=folklore&utm_medium=${medium}&utm_campaign=general&utm_content=button`;

  return (
    <div className="border border-gray-300 p-4">
      <h2 className="typo-small text-gray-400 mt-2">
        Наші партнери
      </h2>
      <div className="mt-2">
        <img src={LogoSrc} alt="Not only pot" title="Not only pot логотип" className="h-8" />
      </div>
      <p className="typo-small mt-2">
        Вироби ручної роботи для створення затишку у вашому домі
      </p>
      <div className="mt-8">
        <Link to={`https://notonlypot.com/shop/cachepots/${imageUrlParams}`} className="block">
          <Image
            image={getImage(data.cachepotImage)}
            alt="Кашпо для сухоцвітів"
            title="Кашпо для сухоцвітів"
          />
        </Link>
        <h3 className="typo-intro mt-2">
          <Link to={`https://notonlypot.com/shop/cachepots/${titleUrlParams}`} className="block" title="Кашпо">
            Кашпо
          </Link>
        </h3>
        <div className="flex justify-between items-center mt-4">
          <span className="typo-small">
            від 60 грн
          </span>
          <Button to={`https://notonlypot.com/shop/cachepots/${buttonUrlParams}`} size="xxs" variant="cta">
            Купити кашпо
          </Button>
        </div>
      </div>
      <div className="mt-8">
        <Link to={`https://notonlypot.com/shop/candlesticks/${imageUrlParams}`} className="block">
          <Image
            image={getImage(data.candlestickImage)}
            alt="Високий свічник для тонкої свічки"
            title="Високий свічник для тонкої свічки"
          />
        </Link>
        <h3 className="typo-intro mt-2">
          <Link to={`https://notonlypot.com/shop/candlesticks/${titleUrlParams}`} className="block" title="Свічники">
            Свічники
          </Link>
        </h3>
        <div className="flex justify-between items-center mt-2">
          <span className="typo-small">
            від 100 грн
          </span>
          <Button to={`https://notonlypot.com/shop/candlesticks/${buttonUrlParams}`} size="xxs" variant="cta" className="typo-xs">
            Купити свічники
          </Button>
        </div>
      </div>
    </div>
  );
};

NOPSidebarBanner.propTypes = {
  medium: PropTypes.string,
};

NOPSidebarBanner.defaultProps = {
  medium: 'sidebar',
};

export default NOPSidebarBanner;
