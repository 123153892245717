import React from "react";

import Button from '../components/Button';
import Link from '../components/Link';
import Seo from '../components/Seo';

import ThumbsUpIcon from '../components/icons/ThumbsUpIcon';
import NOPSidebarBanner from '../components/NOPSidebarBanner';

import OrnamentSource from '../images/ornament.svg';
import HeroVisualSource from '../images/visuals/home-hero.svg';

// markup
const IndexPage = () => {
  return (
    <>
      <Seo
        title="Перлини української народної творчості"
        description="Українська народна творчість: пісні, казки, сміховини, прислів'я та приказки, скоромовки."
      >
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "Перлини української народної творчості",
            "url": "https://folklore.com.ua",
            "sameAs": ["https://www.facebook.com/folklore.com.ua",
                     "https://t.me/folklorecomua",
                     "https://twitter.com/folklorecomua"]
          }`}
        </script>
      </Seo>
      <div className="bg-red-500 text-white">
        <div
          className="container-lg relative"
        >
          <div
            className="hidden md:block absolute inset-0 bg-no-repeat bg-right-top"
            style={{ backgroundImage: `url(${HeroVisualSource})` }}
          />
          <div className="row items-center relative">
            <div className="col-full md:col-6 py-10 md:py-32">
              <h1 className="typo-h1 lowercase max-w-lg mb-4">
                Перлини української народної творчості
              </h1>
              <p className="typo-body">
                Збираємо та зберігаємо культурну спадщину українського народу
              </p>
              <div className="flex flex-col sm:flex-row mt-10">
                <div className="mb-4 sm:mb-0">
                  <Button to="/about/" variant="white" size="xs" className="mr-4">
                    Дізнатись більше
                  </Button>
                </div>
                <div>
                  <Button to="/support/" variant="secondary" size="xs">
                    Підтримати сайт
                    <ThumbsUpIcon className="inline-block h-5 w-5 ml-2" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black text-white">
        <div className="container-lg text-center py-3">
          <p className="typo-body">
            🇺🇦 Як допомогти Україні перемогти? 🇺🇦
            {' '}
            <Link to="/blog/how-to-support-ukraine/" className="underline text-red-500">
              Дізнатись
            </Link>
          </p>
        </div>
      </div>
      <div className="container-md py-10 md:py-24">
        <div className="row">
          <div className="col-full md:col-8">
            <h2 className="typo-h2">
              Категорії
            </h2>
            <div className="row mt-8">
              <div className="col-full md:col-6 md:mt-0">
                <Link to="/pisni/" className="block bg-red-500 text-white px-4 py-4 h-full">
                  <h3 className="typo-h3">
                    пісні
                  </h3>
                  <p className="typo-small mt-4">
                     Пісні історичні, баладні, трудові, обрядові, календарні, колискові, коломийки.
                  </p>
                </Link>
              </div>
              <div className="col-full md:col-6 mt-8 md:mt-0">
                <Link to="/kazky/" className="block bg-red-500 text-white px-4 py-4 h-full">
                  <h3 className="typo-h3">
                    казки
                  </h3>
                  <p className="typo-small mt-4">
                    Народна казка - це народна мудрість, філософія і фантазія. Надзвичайні та життєво-повчальні історії.
                  </p>
                </Link>
              </div>
              <div className="col-full md:col-6 mt-8">
                <Link to="/smikhovyny/" className="block bg-red-500 text-white px-4 py-4 h-full">
                  <h3 className="typo-h3">
                    сміховини
                  </h3>
                  <p className="typo-small mt-4">
                    Дотепні мініатюри, анекдоти, діалогічні сцени, новели-алегорії та нісенітниці
                  </p>
                </Link>
              </div>
              <div className="col-full md:col-6 mt-8">
                <Link to="/skoromovky/" className="block bg-red-500 text-white px-4 py-4 h-full">
                  <h3 className="typo-h3">
                    скоромовки
                  </h3>
                  <p className="typo-small mt-4">
                    Дотепна гра спеціально скомпонованих важковимовних слів, і звуків, що створюють труднощі для швидкої й виразної вимови слів.
                  </p>
                </Link>
              </div>
              <div className="col-full md:col-6 mt-8">
                <Link to="/pryslivia-ta-prykazky/" className="block bg-red-500 text-white px-4 py-4 h-full">
                  <h3 className="typo-h3">
                    прислів'я та приказки
                  </h3>
                  <p className="typo-small mt-4">
                    Короткі влучні вислови.
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-full md:col-4">
            <NOPSidebarBanner medium="home-sidebar" />
          </div>
        </div>
      </div>
      <div className="container-lg pt-10 pb-10 md:pt-0 md:pb-24">
        <div className="row md:flex-nowrap items-center">
          <div className="col-full md:col-6">
            <h2 className="typo-h2">
              Про проєкт
            </h2>
            <p className="typo-intro mt-8">
              Протягом багатовікової історії розвитку української національної культури наш народ дав силу-силенну
              чудових витворів словесного мистецтва. В них проявились яскрава поетична уява, волелюбний дух, глибока
              мудрість, любов до вітчизни, ненависть до гнобителів, віра у перемогу добра і правди.
            </p>
            <p className="typo-intro mt-4">
              Дорогоцінним надбанням нашим є пісні, казки, перекази, легенди, коломийки, прислів’я та приказки,
              народні усмішки, жарти, дотепи, нісенітниці, небилиці та байки й інші жанри усної творчості.
              Проєкт “Перлини української народної творчості” був створений щоб зібрати та зберегти ці перлини, що
              висвічують веселками непідробного гумору, глибокої мудрості та безмежного суму.
            </p>
          </div>
          <div className="hidden md:block col-full md:col-6 md:ml-2/12 md:order-2">
            <img src={OrnamentSource} alt="Ornament" className="w-full"/>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
